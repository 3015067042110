import { useMemo } from "react";

import { Card, Stack, Avatar, Button, useTheme, CardHeader, CardContent, ListItemText } from "@mui/material";

import { useListShipments } from "src/api";
import { useListRecentlyViewedShipments } from "src/api/recently-viewed-shipment";

type Props = {
  handleClick: (id:number) => void;
};

export default function RecentList({ handleClick }: Props) {
  const { recentlyViewedShipments: recent } = useListRecentlyViewedShipments();
  const {shipments } = useListShipments({
    id: recent.map(r => r.shipmentId)
  });

  const sorted = useMemo(
    () => shipments.sort((a, b) => recent.findIndex(r => r.shipmentId === a.id) - recent.findIndex(r => r.shipmentId === b.id)),
    [recent, shipments],
  );

  const theme = useTheme();
  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.success.main,
  ];

  const color = (i:number) => colors[(i-1) % colors.length];

  return (
    <Card>
      <CardHeader title='Recent Shipments' />
      {sorted.map((s) => (
        <CardContent key={s.id} component={Button} onClick={() => handleClick(s.id)} sx={{mb: 2, width: '100%' }}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Avatar
              color={color(s.id)}
              sx={{ fontSize: 14, width: 72, height: 72, background: color(s.id) }}> #{`${s.id}`} </Avatar>
            <ListItemText primary='Order' secondary={`${s.orderNumber}`} />
            <ListItemText primary='Master BOL' secondary={s.masterBolNumber} />
            <ListItemText primary='House BOL' secondary={s.houseBolNumber} />
            <ListItemText primary='Primary Container #' secondary={s.primaryContainerNumber} />
            <ListItemText primary='Secondary Container #' secondary={s.secondaryContainerNumber || '-'} />
            <ListItemText primary='Primary Chassis Number' secondary={s.primaryChassisNumber || '-' } />
            <ListItemText primary='Primary Chassis Number' secondary={s.secondaryChassisNumber || '-' } />
          </Stack>
        </CardContent>
      ))}
    </Card>
  );
}
