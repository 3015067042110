import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from 'src/utils/axios';

import { RecentlyViewedShipment } from 'src/types';

const URL = endpoints.recentlyViewedShipments;

export function useListRecentlyViewedShipments(filter?: any) {
  const request: any = [URL, { params: { filter } }];

  const { data, mutate, isLoading, error, isValidating } = useSWR(request, fetcher);

  const memoizedValue = useMemo(
    () => ({
      recentlyViewedShipments: (data || []) as RecentlyViewedShipment[],
      mutate,
      recentlyViewedShipmentsLoading: isLoading,
      recentlyViewedShipmentsError: error,
      recentlyViewedShipmentsValidating: isValidating,
      recentlyViewedShipmentsEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}
