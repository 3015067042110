import { memo, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useEventListener } from 'src/hooks/use-event-listener';

import { useSearchShipments } from 'src/api';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';

import ResultList from './result-list';
import RecentList from './recent-list';

// ----------------------------------------------------------------------

const FIELDS = [
  'id',
  'orderNumber',
  'masterBolNumber',
  'houseBolNumber',
  'primaryContainerNumber',
  'secondaryContainerNumber',
  'primaryChassisNumber',
  'secondaryChassisNumber',
];

function Searchbar() {
  const theme = useTheme();
  const router = useRouter();
  const search = useBoolean();
  const lgUp = useResponsive('up', 'lg');
  const [searchQuery, setSearchQuery] = useState('');

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  const handleClick = useCallback((id: number) => {
    router.push(paths.dashboard.general.shipments.edit(id));
    handleClose();
  }, [handleClose, router]);

  useEventListener('keydown', handleKeyDown);
  const handleSearch = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const filter: GridFilterModel = {
    logicOperator: GridLogicOperator.Or,
    items: FIELDS.map((field) => ({ field, operator: 'contains', value: searchQuery })),
  };

  const { results: shipments } = useSearchShipments(filter);

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {lgUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>}
    </Stack>
  );

  // eslint-disable-next-line no-nested-ternary
  const body = searchQuery ? (
    shipments.length ? (
      <ResultList
        searchQuery={searchQuery}
        shipments={shipments}
        handleClick={handleClick}
      />
    ) : (
      <SearchNotFound query={searchQuery} sx={{ py: 10 }} />
    )
  ) : (
    <RecentList handleClick={handleClick} />
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="lg"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 770 }}>
          {body}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);
